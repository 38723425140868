import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../Styles/HomePage.css"

import 'bootstrap/dist/css/bootstrap.min.css';
import ReusableCard from './ReusableCard';
import Contact from './Contact';






const HomePage = () => {
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
 
  return (
    <section  >

      <div className=' overflow-hidden ' >
        {/* overflow-hidden p-0 */}
        <div className='Background'>
        <section className=' container' >
<Col lg={6}  md={12} xs={12} sm={12} xl={6}className='mwahalla-intro'>
<div className='mwahala2'>
<h1 className='neighnourhood'>Building a better neighbourhood</h1>

<p className='leveraging'>by leveraging trust and technology.</p>
<div className='download d-flex'>
                <div className='d-flex justify-content-center align-items-center text-center book-a-demo-btn' style={{ cursor: "pointer" }} onClick={() => scrollToSection("contact")}>

<span className='book-a-demo-text'  > Book a Demo</span>
</div>
                </div>
</div>
</Col>
   
      
     </section>
     </div>
     <div className='bg-color'>
     <section className='container'>
        <div className='our-vision'>
            <Col lg={7} xs={12} className='vision2'>
            <h1 className='our-vision2'><span className='our'>Our</span> <span className='vision'>Vision</span></h1>
            </Col>
        </div>
        <Row>
            <Col lg={7} xl={7} md={7} sm={12} xs={12} >
            <div>

                <p className='vision-desc'>Bringing together all aspects of community living through technology to deliver seamless security, convenience, and a strong sense of community.</p>
            </div>
            </Col>
            <Col lg={5} xl={5} md={5} sm={12} xs={12}>
            <img src="./Images/Our-Vision.png" className='vision-img'/>
            </Col>
        </Row>

     </section>
     </div>
     <section className='container what-is'>
        <Row>
        <Col lg={8} xl={8} md={12} sm={12} xs={12}>
        <div>
            <h1 className=''><span className='mwahalla-description-header'>What is</span> <span className='mwahalla-description-header2'>DoorPe?</span></h1>
            <p className='about-mwhala'>DoorPe is an app-based solution for security and apartment management. It empowers apartment management to digitize their daily operations and efficiently safeguard their gated communities.</p>
            <p className='about-mwhala'>DoorPe brings modern technology to traditional apartment management, streamlining processes and enhancing overall security.</p>
        </div>
        <Row>
            <Col className='seamless-visitor ' sm={12} xs={12} lg={6} md={12}>
                <h5 className='seamless-visitor-text p-1'>Seamless Visitor Management</h5>
                <p className='effortlessly'>Effortlessly <span className="highlight">manage and track visitors </span><br></br><span className="highlight2" >entering and exiting the community</span> with a user-friendly interface.</p>
            </Col>
            <Col className='d-flex' style={{flexDirection:"column"}} sm={12} xs={12} lg={6} md={12}>
            <Col className='resident-security'>
                <h5 className='resident-security-text p-1'>Resident Security</h5>
                <p className=' residet-home'>Enhance home safety through <span className="highlight">personalized access control.</span></p>

            </Col>
            <Col className='community-tools' sm={12} xs={12} lg={6} md={12}>
                <h5 className='community-tools-text p-1'>Community Tools</h5>
                <p className=' community-tools2'>Engage with the community through<span className="highlight3">announcements and events</span></p>

            </Col>
            </Col>
        </Row>
            </Col>
            <Col lg={4} xl={4} sm={12} md={12} xs={12} className="d-flex justify-content-center align-items-center ">
  <div className='app-screen'>
    <img src="./Images/Mwahalla-app.png" className='img-fluid mwahalla-app-screen' />
  </div>
</Col>

</Row>
        </section>
        <div className='management'>
            <section className='container' id="for-management">
                <div>
                <h1 className='d-flex'>
                  
                <p className='for'>for </p>{''}<p className='management-text '>Management</p>
              </h1>
              </div>
              
                <ReusableCard/>

            </section>
            
        </div>
        <section className='container residents' id='for-residents'>
        <h1>
                <span className='for-residents'>for</span>  <span className='residents-text'>Residents</span>
                </h1>
                <Row className='for-residents-services justify-content-center'>
  <Col lg={12} sm={12} xs={12} md={12} className="d-flex justify-content-center">
    <img 
      src="./Images/for_residents.png" 
      className='img-fluid for-residents-img'
     
    />
  </Col>
</Row>



        </section>
        <div className='mwahalla-demo'>
            <section>
            <div className="d-flex justify-content-center align-items-center" >
  <Row>
    <Col lg={12} md={12} sm={12} xs={12} className='demo1'>
      <h1 className='text-center'>
        <span className='Up-for'>Up for a </span>
        <span className='mwahal-show'>DoorPe show?</span>
      </h1>
    </Col>
    <Col lg={12} md={12} sm={12} xs={12} className='demo1'>
      <div className='download2 d-flex justify-content-center'>
        <div
          className='d-flex justify-content-center align-items-center text-center book-a-demo-btn2'
          style={{ cursor: "pointer" }}
          onClick={() => scrollToSection("contact")}
        >
          <span className='book-a-demo-text2'>Book a Demo</span>
        </div>
      </div>
    </Col>
  </Row>
</div>

            </section>
        </div>
        <div id='contact' >
        <Contact />
        </div>
     </div>

    </section>


  )
}

export default HomePage