import React from 'react'
import { Col, Row } from 'react-bootstrap'
import "../Styles/HomePage.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city:'',
        socityName:'',
      
    });
    console.log(formData)
  
    const [submitting, setSubmitting] = useState(false);
    const emailBody = `
    <div style="background-color: #f5f5f5; padding: 20px; font-family: Arial, sans-serif;">
        <h4 style="color: #333; font-size: 18px; margin-bottom: 10px;">Apply Job Form Response</h4>
     
   
      
        <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Name:</strong> ${formData?.name}
        </div>
        <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Phone:</strong> ${formData.mobile}
        </div>
        <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Email:</strong> ${formData.email}
        </div>
          <div style="margin-bottom: 10px;">
            <strong style="color: #555;">City:</strong> ${formData.city}
        </div>
          <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Society Name:</strong> ${formData.societyName}
        </div>
     

      
    </div>
    


`;
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);
  ////support@honc.io
    axios
      .post('https://api.honc.io/admin/chatbot/send-email', {
        to: 'info@mwhala.com',
        subject: 'Apply job form response from website',
      
        body: emailBody,
      })
      .then((response) => {
        console.log('Email sent successfully:', response.data);

        Swal.fire('Success', 'Your response has been submitted successfully', 'success');
    
        setFormData({
       
          name: '',
          mobile: '',
      email: '',
      city:'',
    societyName:''
   
        });
        setSubmitting(false);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitting(false);
      });
  };

    return (
        <div className='contact-form'>
            <section className='container'>
                <Row>
                    <Col lg={5} md={5} xs={12} sm={12} className='contact-info'>
                        <div>
                            <img src="./Images/Mwahalla_logo.png" className='mwahalla_contact_logo' />
                        </div>
                        <div className='address'>
                            <h6 className='address-heading'>Address</h6>
                            <p className='address-details'>Off #31, FBF Commercial<br></br> Complex, Al Murtafah Street,<br></br>Al’Seeb, Sultanate of Oman</p>
                            <div className='d-flex mobile-details'>
                                <span className='d-flex'>
                                    <img src="./Images/phone.png" className='phone-icon' /></span><span className='contact-num'>+968 9242 0334</span>
                            </div>
                            <div className='d-flex email-details'>
                                <span  className='d-flex'>
                                    <img src="./Images/gmail.png" className='phone-icon' /></span><span className='contact-num'>info@door.pe</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} md={7} xs={12} sm={12} className='contact-info2'>

                        <h5 className='Contact-us-form-heading'>
                            Contact Us
                        </h5>
                        <form style={{ flexDirection: "column" }} onSubmit={handleSubmit} method="post">
                            <div className=''>
                                <div className='bottom '></div>
                            </div>

                            <Row className='contact-top'>
                                <Col lg={6} xl={6} md={6} xs={12} sm={12}>
                                    <div class="form-group  d-flex name-text">
                                        <div>
                                        <p style={{color:'#FFFFFF'}} className='form-label'>Name</p>
                                        <input type="text" className="form-control name-form" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={formData.name}
                                          onChange={handleInputChange}  placeholder="Name" required />
</div>
                                    </div>
                                </Col>
                                <Col lg={6} xl={6} md={6} xs={12} sm={12}>
                                    <div class="form-group email-grp  form-grp2 d-flex ">
                                    <div>
                                    <p style={{color:'#FFFFFF'}} className='form-label mobile2'>Mobile Number</p>
                                        <input type="tel" className="form-control mobile" id="" name="mobile"
                                              value={formData.mobile}
                                            onChange={e => {
                                                const numericValue = e.target.value.replace(/\D/g, '');
                                             handleInputChange({ target: { name: 'mobile', value: numericValue } });
                                            }} placeholder="Mobile Number" required />

</div>

                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col lg={6} xl={6} md={6} xs={12} sm={12}>
                                <div>
                                    <p style={{color:'#FFFFFF'}} className='form-label'>Email</p>
                                    <input type="email" class="form-control email" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" value={formData.email}
                                     onChange={handleInputChange}    placeholder="Email" required />
                                        </div></Col>

                                <Col lg={6} xl={6} md={6} xs={12} sm={12}>
                                <div>
                                    <p style={{color:'#FFFFFF'}} className='form-label city2'>City</p>
                                    <input type="text" class="form-control email" id="" aria-describedby="" name="city" value={formData.value}
                                   onChange={handleInputChange} placeholder="City" required />
                                    </div>
                                    </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col lg={12} md={12} xs={12} sm={12} xl={12}>     
                                <p style={{color:'#FFFFFF'}} className='form-label'>Socity Name</p>
                                <input type="text" class="form-control email society-name" id="" aria-describedby=""  name="societyName" value={formData.value}
                                 onChange={handleInputChange}   placeholder="Society Name" required /></Col>
                            </Row>
                            <Row>
                                <div className='email-grp   ' style={{ alignSelf: "start" }}>

                                    <button type="submit" value={submitting ? 'Submitting...' : 'Submit'} class="btn btn-lg apply-submit-btn" >Submit</button>

                                </div>
                            </Row>
                        </form>
                    </Col>
                </Row>
                {/* <Row className='mt-5 d-flex'>
                    <Col lg={6} md={6}  className='d-flex mt-5'>
                   <Col lg={1} md={1} xs={1} sm={1}><img src="./Images/facebook.png"/></Col>
                   
                   
                   <Col lg={1} md={1} xs={1} sm={1}><img src="./Images/Instagram.png"/></Col>
                    
                    <Col lg={1} md={1} xs={1} sm={1} ><img src="./Images/twitter.png" className='social-media-icons'/></Col>
                    </Col>
                </Row> */}
                <Row className='mt-3'>
                    <Col lg={8} className='rights'>
                    <p style={{color:"#FFFFFF"}} className='all-rights'>©2024. All rights reserved. Cultural Links Technology LLC.</p>
                    </Col>
                    <Col lg={4} className="policy">
  <p style={{ color: "#FFFFFF" }} className='privacy-policy'>Privacy Policy</p>
</Col>

                </Row>
            </section>
        </div>
    )
}

export default Contact